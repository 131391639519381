<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>医患管理</el-breadcrumb-item>
            <el-breadcrumb-item>医生信息</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="addDoc('new','')" size="small" style="float: left;">添加</el-button>
            <!-- <p style="float: left;margin: 0;">筛选科室：</p> -->
            <!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="search" style="width: 15%;"></el-input> -->
            <el-select size="small" filterable v-model="select" placeholder="请选择科室" style="margin-right: 10px;float: left;width: 140px;">
                <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
            <el-select size="small" v-model="select2" placeholder="请选择状态" style="float: left;width: 80px;">
                <el-option v-for="item in options2" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-input size="small" placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 220px;margin-left: 10px;float: left;" @clear="clearsearch">
            </el-input>
            <el-button size="small" type="primary" style="margin-left:10px" @click="query">查询</el-button>
        </div>
        <el-table :data="list" border>
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="姓名" width="90"></el-table-column>
            <el-table-column label="电话" prop="tel" width="120"></el-table-column>
            <el-table-column label="年龄" prop="age" width="60"></el-table-column>
            <el-table-column label="平台内科室" prop="dp_name" width="140"></el-table-column>
            <el-table-column label="性别" prop="sex" width="60"></el-table-column>
            <el-table-column label="所在医疗机构" prop="hospital" width="200"></el-table-column>
            <el-table-column label="医疗机构级别" prop="hospital_rank" width="120"></el-table-column>
            <el-table-column label="所在医疗机构科室" prop="depart" width="150"></el-table-column>
            <el-table-column label="职称" prop="rank" width="100"></el-table-column>
            <!-- <el-table-column label="擅长治疗" prop="goodat"></el-table-column> -->
            <el-table-column width="220" fixed="right">
                <template slot-scope="scope">
                    <div class="opt doc">
                         <el-button type="primary" size="mini" @click="addDoc('bj',scope.row)">编辑</el-button>
                         <el-button type="danger" size="mini"  @click="del(scope.row,scope.$index)">删除</el-button>
                        <!-- <a @click="addCase(scope.row)">添加病例</a> icon="el-icon-edit" icon="el-icon-delete" icon="el-icon-remove-outline" icon="el-icon-circle-check"
                        <a @click="lookpat(scope.row)">查看患者</a> -->
                         <el-button type="info" size="mini"  v-if="scope.row.flag == 1" @click="stop(scope.row,'ty')">停用</el-button>
                         <el-button type="success" size="mini"  v-if="scope.row.flag == 0" @click="stop(scope.row,'qy')">启用</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                select: '',
                search: '',
                options: [],
                options2: [
                    { id: 0, name: '停用' },
                    { id: 1, name: '启用' },
                ],
                select2: 1,
                num: '',
                search: ''
            }
        },
        mounted() {
            this.getdata(1, this.PageSize, '', '');
            this.getkeshilist();
        },
        methods: {
            getkeshilist() {
                this.axios.get('/gu/get_gu_department')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options = res.data.result;
                            this.options.unshift({ id: '-1', name: '无' });
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getdata(page, size, id, type) {
                this.list = [];
                this.axios.get("/gu/get_gu_docs?page=" + page + "&size=" + size + "&dp_id=" + id + "&flag=" + type + "&search=" + this.search)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            this.totalCount = 0;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            addDoc(type, item) {
                if (type == 'new') {
                    this.$router.push({ path: '/doctorManage/doctorDetail', query: { type: type } });
                } else {
                    this.$router.push({ path: '/doctorManage/doctorDetail', query: { type: type, id: item.id } });
                    // this.$router.push({ name: 'DoctorDetail', params: { type: type, id: item.id } });
                }
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage, this.PageSize, '', this.select2);
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该医生信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_doc", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            addCase(item) {
                this.$router.push({ path: '/addCase', query: { id: item.id } });
            },
            lookpat(item) {
                this.$router.push({ path: '/docLookPat', query: { id: item.id } });
            },
            query() {
                if (this.search != '') {
                    this.axios.post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.search ,is_flag:'all'}))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.list = rs.data.result;
                                this.totalCount = rs.data.result.length;
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '没有搜索到该医生'
                                });
                                console.log(rs);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    if (this.select == -1) {
                        this.select = '';
                    }
                    if (this.select2 == 0) {//停用
                        this.getdata(1, this.PageSize, this.select, 0);
                    } else {//启用
                        this.getdata(1, this.PageSize, this.select, 1);
                    }
                }
            },
            stop(row, type) {
                let str = '', str1 = '';
                if (type == 'ty') {//0停用
                    str = '停用成功';
                    str1 = '此操作将停用该医生, 是否继续?';
                    this.num = 0;
                } else {//1启用
                    str = '启用成功';
                    str1 = '此操作将启用该医生, 是否继续?';
                    this.num = 1;
                }
                this.$confirm(str1, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/update_gu_doc_flag", this.qs.stringify({ id: row.id, flag: this.num }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: str,
                                    type: 'success'
                                });
                                this.reload();
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
            clearsearch() {
                this.getdata(1, this.PageSize, '', '');
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
        float: left;
    }

    .el-dialog {
        width: 40%;
    }

    .el-select {
        float: left;
    }
    .add .el-button {
        margin-right: 10px;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
    .el-input--suffix .el-input__inner{
        width: 100%;
    }
    .add .el-input--suffix{
        width: 100%;
    }
</style>